<template>
	<div style="background-color: #f8f9fb; min-height: 100%">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button class="arrow_white" @click="goBack">
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">포인트 추가 적립</h2>
			</div>
		</div>

		<div class="subindex_wrap pt-50">
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_mainbox">
						<div>
							<strong class="size-px-18 color-333">My 카르텔 추가 적립</strong>
							<p class="mt-5 size-px-16 fw-500 color-333">나의 카르텔 활성화에 따라 추가 적립</p>
							<table class="mt-20">
								<colgroup>
									<col style="width: 40%" />
									<col />
								</colgroup>
								<thead>
								<tr>
									<th>항목</th>
									<th>보상(MAF포인트)</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>일일 신규 회원수</td>
									<td>100 MAF POINT</td>
								</tr>
								<tr>
									<td>공감</td>
									<td>2 MAF POINT</td>
								</tr>
								</tbody>
							</table>
							<ul class="list_dot mt-15">
								<li class="color-333">공감의 경우 My 카르텔 게시글에 달리는 1공감에 대한 보상 지급됩니다.</li>
							</ul>
						</div>

						<div class="pt-40">
							<strong class="size-px-18 color-333">카르텔 활동 적립</strong>
							<p class="mt-5 size-px-16 fw-500 color-333">카르텔 공감과 댓글만으로 추가 적립</p>
							<table class="mt-20">
								<colgroup>
									<col style="width: 40%" />
									<col />
								</colgroup>
								<thead>
								<tr>
									<th>항목</th>
									<th>보상(MAF포인트)</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>공감</td>
									<td>10 MAF POINT</td>
								</tr>
								<tr>
									<td>댓글 작성</td>
									<td>20 MAF POINT</td>
								</tr>
								</tbody>
							</table>
							<ul class="list_dot mt-15">
								<li class="color-333">공감과 댓글 작성에 의한 보상은 실시간으로 지급됩니다.</li>
								<li class="color-333">출석체크 및 공감과 댓글 작성에 의한 보상은 하루 최대 500MAF 포인트로 제한됩니다.</li>
								<li class="color-333">하루 최대 제한은 00시 기준으로 초기화됩니다.</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="subindex_item">
				<div class="container">
					<div class="subindex_box_1">
						<p class="size-px-16 fw-500 color-333">꼭 알아두세요.</p>
						<ul class="list_dot mt-15">
							<li class="color-333">A카드 소지자에 한해 2배의 보상률이 적용됩니다.</li>
							<li class="color-333">지급된 MAF 포인트는 타인에게 양도/판매가 불가합니다.</li>
							<li class="color-333">지급된 MAF 포인트는 MAF 코인으로 전환이 가능합니다.</li>
							<li class="color-333">악용된 사례로 포인트를 획득한 경우 해당 계정과 카르텔은 차단될 수 있습니다.</li>
							<li class="color-333">(주)메타쿱의 사정으로 출석체크 보상 변경 및 중단될 수 있습니다.</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PointMore'
		, props: ['user']
		, data: function(){
			return {
				name: 'point_more'
				, program: {
					name: ''
					, title: ''
					, type: 'mypage'
					, not_footer: true
				}
			}
		}
		, computed: {

		}
		, methods: {
			goBack: function(){
				this.$bus.$emit('goBack')
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
		}
	}
</script>

<style scoped>
table {
	width: 100%;
	border-top: 1px solid var(--light-Gray01);
	border-collapse: collapse;
}
th,
td {
	border-bottom: 1px solid var(--light-Gray01);
	border-left: 1px solid var(--light-Gray01);
	padding: 15px 10px;
	color: var(--dark-Gray02);
}
th:first-child,
td:first-child {
	border-left: none;
}
th {
	background: rgba(233, 233, 233, 0.2);
	font-size: 14px;
	font-weight: 500;
}
td {
	font-size: 13px;
	font-weight: 400;
}
</style>
